<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="modal-header">
        <button class="modal-close" @click="handleClose">&times;</button>
      </div>
      <div class="modal-content">
        <div class="container">
          <div class="row justify-content-center">
            <div role="main" id="website-price-list-amp-brochure-50d11167283034e4e75e"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    formInstance: null
  }),
  methods: {
    handleClose() {
      if (this.formInstance) {
        const formElement = document.getElementById('website-price-list-amp-brochure-50d11167283034e4e75e');
        if (formElement) {
          formElement.innerHTML = '';
        }
        this.formInstance = null;
      }
      this.$emit('close');
    },
    initializeForm() {
      if (window.RDStationForms) {
        this.formInstance = new window.RDStationForms('website-price-list-amp-brochure-50d11167283034e4e75e', 'null').createForm();
      }
    }
  },
  mounted() {
    if (!document.getElementById('rdstation-forms')) {
      const script = document.createElement('script');
      script.src = 'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js';
      script.id = 'rdstation-forms';
      script.onload = () => {
        this.initializeForm();
      }
      document.head.appendChild(script);
    } else {
      this.initializeForm();
    }
  },
  beforeUnmount() {
    this.handleClose();
    const script = document.getElementById('rdstation-forms');
    if (script) {
      document.head.removeChild(script);
    }
  }
}
</script>

<style scoped>
.modal-backdrop .modal {
  background-color: transparent;
}
</style>